var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tablePageLocation",staticClass:"table-page"},[_c('v-card-head',[_c('v-card-head-label',[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm._f("translate")('ui.labels.locations')))]),_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm._f("translate")('infotext.here_you_create_and_manage_your_locations')))])]),_c('v-card-head-label',[_c('span',{staticClass:"link-text",on:{"click":function($event){return _vm.$refs.tableTree.collapseAll()}}},[_vm._v(_vm._s(_vm._f("translate")('ui.buttons.collapse_all')))])])],1),_c('vue-scroll',{attrs:{"ops":_vm.ops}},[_c('v-table',[_c('v-table-head',[_c('th',{staticClass:"table-page__table-header"},[_c('span',{staticStyle:{"width":"auto"}},[_vm._v(_vm._s(_vm._f("translate")('ui.labels.location')))]),_c('div',{staticClass:"flex header-right--titles"},[_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("translate")('pages.administration.employee_count')))]),_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("translate")('ui.labels.language')))])])])]),_c('v-table-body',{attrs:{"cols":1,"empty":!_vm.locations.length,"loaded":_vm.loaded,"no-result":!_vm.locations.length}},[_c('v-scrollable',{attrs:{"bottom-offset":10}},[_c('v-table-tree',{ref:"tableTree",class:{ 'sl-vue-tree--external-drag': _vm.externalDragMode },attrs:{"show-last-item":"","allow-drop-inside-leaf":"","allow-toggle-branch":"","is-last-item-selected":_vm.isNotAssignedSelected,"external-dragging":_vm.externalDragMode},on:{"drop":_vm.onDrop,"select":_vm.onSelect,"externaldrop":_vm.onExternalDrop,"lastitemclick":_vm.onNotAssignedSelect,"rootlastitemclick":function($event){return _vm.createLocationInTree({ toNode: null })}},scopedSlots:_vm._u([{key:"toggle",fn:function(ref){
var node = ref.node;
return [_c('div',{staticClass:"sl-vue-tree__chevron"},[_c('v-chevron',{attrs:{"list-mode":"","value":node.isExpanded}})],1)]}},{key:"title",fn:function(ref){
var node = ref.node;
return [(node.isLeaf)?_c('span',{staticClass:"sl-vue-tree__node-icon"},[_c('i',{staticClass:"fa fa-map-marker-alt"})]):_vm._e(),_c('span',{staticClass:"sl-vue-tree__node-title"},[_c('v-editable',{attrs:{"ghost":"","toggle-by-icon-only":"","init-state":node.isEditing,"error-text":node.data.errorText,"pencil-class":"v-table-tree--show-on-hover"},on:{"close":function($event){return _vm.onTitleUpdate($event, node)}}},[_c('span',[_vm._v(_vm._s(node.title))])])],1)]}},{key:"sidebar",fn:function(ref){
var node = ref.node;
return [_c('span',{staticClass:"sidebar-column"},[_vm._v(_vm._s(node.isLeaf ? node.data.employeeCount : node.data.allChildEmployeeCount))]),_c('span',{staticClass:"sidebar-column",on:{"mousedown":function($event){$event.stopPropagation();},"mouseup":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":"","gray":""},on:{"click":function($event){return _vm.showLangModal(node)}}},[_vm._v(_vm._s(((node.data.defaultLanguage && node.data.defaultLanguage.id) || '').toUpperCase() || 'EN'))])],1),_c('div',{staticClass:"sidebar-controls v-table-tree--show-on-hover action-buttons--hover",style:({left: (_vm.posLeft + "px")}),on:{"mousedown":function($event){$event.stopPropagation();},"mouseup":function($event){$event.stopPropagation();}}},[(!node.data.isNew)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","stop-propagation":""},on:{"click":function($event){return _vm.createLocationInTree({ toNode: node })}}},[_c('span',[_vm._v("+")]),_c('i',{staticClass:"fa fa-map-marker-alt"})]):_vm._e(),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('infotext.remove')),expression:"$t('infotext.remove')"}],attrs:{"icon":"","danger":"","stop-propagation":""},on:{"click":function($event){return _vm.onRemove(node)}},nativeOn:{"mousedown":function($event){return _vm.onBeforeRemove(node)}}},[_c('i',{staticClass:"fa fa-trash-alt"})])],1)]}},{key:"list-end",fn:function(ref){
var node = ref.node;
return [(node)?[_c('div',[_c('span',{staticClass:"sl-vue-tree__node-icon"},[_c('i',{staticClass:"fa fa-users"})]),_c('span',{staticClass:"sl-vue-tree__node-title"},[_c('span',[_vm._v(_vm._s(_vm._f("translate")('ui.labels.not_assigned')))])])]),_c('div',{staticClass:"not-assigned"},[_c('span',{staticClass:"sidebar-column"},[_vm._v(_vm._s(node.data.employeeCount))]),_c('span',{staticClass:"sidebar-column"}),_c('div',{staticClass:"sidebar-controls"})])]:_vm._e()]}}]),model:{value:(_vm.locations),callback:function ($$v) {_vm.locations=$$v},expression:"locations"}},[_c('template',{slot:"root-list-end"},[_c('span',[_c('span',{staticClass:"sl-vue-tree__node-icon"},[_c('i',{staticClass:"fa fa-plus"})]),_c('span',{staticClass:"sl-vue-tree__node-title"},[_vm._v(_vm._s(_vm._f("translate")('pages.administration.add_new_location')))])])])],2)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"500","value":_vm.langModal.showChangeLangModal},on:{"input":_vm.closeLangModal}},[_c('change-lang-modal',{attrs:{"node":_vm.langModal.modalData},on:{"on-language-update":_vm.onLanguageUpdate,"close":_vm.closeLangModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }