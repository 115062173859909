<template lang="pug">
  right-panel-layout
    template
      intro-page(
        v-if="isIntroMode"
        @on-add-location="onAddLocation")
      table-page(
        v-else)

    template(slot="panel")
      tabs-panel

</template>

<script>
import RightPanelLayout from '../common/RightPanelLayout'
import TabsPanel from './common/TabsPanel'

import IntroPage from './pages/Intro'
import TablePage from './pages/Table'

export default {
  name: 'Locations',

  components: {
    RightPanelLayout,
    TabsPanel,
    IntroPage,
    TablePage
  },

  data: () => ({
    isIntroMode: false
  }),

  methods: {
    onAddLocation () {
      this.isIntroMode = false
    }
  }
}

</script>

<style lang="scss">
</style>
