<template lang="pug">
  .table-page(ref="tablePageLocation")
    v-card-head
      v-card-head-label
        h2.title {{ 'ui.labels.locations' | translate }}
        p.subtitle {{ 'infotext.here_you_create_and_manage_your_locations' | translate }}

      v-card-head-label
        span.link-text(@click="$refs.tableTree.collapseAll()")
          | {{ 'ui.buttons.collapse_all' | translate }}
    vue-scroll(:ops="ops")
      v-table
        v-table-head
          th.table-page__table-header
            span(style="width:auto") {{ 'ui.labels.location' | translate }}
            div.flex.header-right--titles
              span.text-right {{ 'pages.administration.employee_count' | translate }}
              span.text-right {{ 'ui.labels.language' | translate }}

        v-table-body(
          :cols="1"
          :empty="!locations.length"
          :loaded="loaded"
          :no-result="!locations.length")
          v-scrollable(
            :bottom-offset="10")

            v-table-tree(
              ref="tableTree"
              v-model="locations"
              show-last-item
              allow-drop-inside-leaf
              allow-toggle-branch
              :is-last-item-selected="isNotAssignedSelected"
              :class="{ 'sl-vue-tree--external-drag': externalDragMode }"
              :external-dragging="externalDragMode"
              @drop="onDrop"
              @select="onSelect"
              @externaldrop="onExternalDrop"
              @lastitemclick="onNotAssignedSelect"
              @rootlastitemclick="createLocationInTree({ toNode: null })")
              template(
                slot="toggle"
                slot-scope="{ node }")
                .sl-vue-tree__chevron
                  v-chevron(
                    list-mode
                    :value="node.isExpanded")

              template(
                slot="title"
                slot-scope="{ node }")
                span.sl-vue-tree__node-icon(
                  v-if="node.isLeaf")
                  i.fa.fa-map-marker-alt
                span.sl-vue-tree__node-title
                  v-editable(
                    ghost
                    toggle-by-icon-only
                    :init-state="node.isEditing"
                    :error-text="node.data.errorText"
                    pencil-class="v-table-tree--show-on-hover"
                    @close="onTitleUpdate($event, node)")
                    span {{ node.title }}

              template(
                slot="sidebar"
                slot-scope="{ node }")
                span.sidebar-column {{ node.isLeaf ? node.data.employeeCount : node.data.allChildEmployeeCount }}
                span.sidebar-column(
                  @mousedown.stop
                  @mouseup.stop)
                  v-btn(
                    icon
                    gray
                    @click="showLangModal(node)")
                    | {{ ((node.data.defaultLanguage && node.data.defaultLanguage.id) || '').toUpperCase() || 'EN' }}
                .sidebar-controls.v-table-tree--show-on-hover.action-buttons--hover(
                  :style="{left: `${posLeft}px`}"
                  @mousedown.stop
                  @mouseup.stop)
                  v-btn.mr-2(
                    v-if="!node.data.isNew"
                    icon
                    stop-propagation
                    @click="createLocationInTree({ toNode: node })")
                    span +
                    i.fa.fa-map-marker-alt
                  v-btn(
                    icon
                    danger
                    stop-propagation
                    v-tooltip="$t('infotext.remove')"
                    @click="onRemove(node)"
                    @mousedown.native="onBeforeRemove(node)")
                    i.fa.fa-trash-alt

              template(
                slot="list-end"
                slot-scope="{ node }")
                template(v-if="node")
                  div
                    span.sl-vue-tree__node-icon
                      i.fa.fa-users
                    span.sl-vue-tree__node-title
                      span {{ 'ui.labels.not_assigned' | translate }}
                  .not-assigned
                    span.sidebar-column {{ node.data.employeeCount }}
                    span.sidebar-column
                    .sidebar-controls

              template(
                slot="root-list-end")
                span
                  span.sl-vue-tree__node-icon
                    i.fa.fa-plus
                  span.sl-vue-tree__node-title {{ 'pages.administration.add_new_location' | translate }}

    v-dialog(
      width="500"
      :value="langModal.showChangeLangModal"
      @input="closeLangModal")
      change-lang-modal(
        :node="langModal.modalData"
        @on-language-update="onLanguageUpdate"
        @close="closeLangModal")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MODAL_TYPES } from '@/util/constants.js'

import ChangeLangModal from '../modals/ChangeLangModal'

export default {
  name: 'Table',

  components: {
    ChangeLangModal
  },

  async mounted () {
    await this.loadLocationsTree()
    this.loaded = true
    this.posLeft = this.defaultPosRight()
  },

  data: () => ({
    ops: {
      scrollPanel: {
        scrollingX: true
      },
      rail: {
        gutterOfEnds: '15px'
      }
    },
    loaded: false,
    langModal: {
      showChangeLangModal: false,
      modalData: null
    },
    posLeft: 0
  }),

  methods: {
    ...mapActions('modals', [
      'openConfirmModal'
    ]),

    ...mapActions('locations', [
      'updateItem',
      'onMoveItems',
      'removeItem',
      'selectLocation',
      'selectUnassigned',
      'loadLocationsTree',
      'createLocationInTree',
      'moveEmployeesTo'
    ]),

    closeLangModal () {
      this.langModal = {
        showChangeLangModal: false,
        modalData: null
      }
    },

    showLangModal (node) {
      this.langModal = {
        showChangeLangModal: true,
        modalData: node
      }
    },

    onTitleUpdate (value, node) {
      const fields = {
        name: value
      }
      this.updateItem({ node, fields })
    },

    onLanguageUpdate (value, node) {
      const fields = {
        defaultLanguageId: value.id,
        defaultLanguage: value
      }
      this.updateItem({ node, fields })
      this.closeLangModal()
    },

    onSelect (nodes) {
      this.selectLocation(nodes[0])
    },

    onNotAssignedSelect (node) {
      if (node) {
        this.selectUnassigned(node)
      }
    },

    onDrop (nodes, params) {
      this.onMoveItems({ nodes, params })
    },

    onExternalDrop (payload) {
      this.moveEmployeesTo(payload.node)
    },

    onBeforeRemove (node) {
      if (node.data.isNew) {
        node.data.isDeleted = true
      }
    },

    onRemove (node) {
      if (!node.children.length && !node.data.employeeCount) {
        this.removeItem({ node: node })
      } else {
        let subtitle
        let title
        if (!node.isLeaf) {
          subtitle = this.$t('infotext.delete_not_empty_location')
          title = this.$t('ui.labels.warning') + '!'
        } else {
          title = this.$t('ui.modals.delete_message_with_params', { name: node.data.name })
        }

        const options = {
          content: {
            title: title,
            subtitle: subtitle
          },

          props: {
            type: node.isLeaf ? MODAL_TYPES.WARNING_ORANGE : MODAL_TYPES.WARNING_RED
          },

          buttons: {
            apply: {
              label: 'ui.buttons.delete',
              callback: this.removeItem,
              params: { node: node }
            }
          }
        }

        this.openConfirmModal(options)
      }
    },
    defaultPosRight () {
      return this.$refs.tablePageLocation.getBoundingClientRect().width - 72 - 6// 72 - width block actions, 6 - offset right
    },

    handleScroll (vertical, horizontal, nativeEvent) {
      this.posLeft = this.defaultPosRight() + horizontal.scrollLeft
    }
  },

  computed: {
    locations: {
      get () {
        return this.$store.getters['locations/locationsTree']
      },

      set (value) {
        this.$store.commit('locations/SET_LOCATIONS_TREE', value)
      }
    },

    ...mapGetters('locations', [
      'selectedLocation',
      'externalDragMode'
    ]),

    isNotAssignedSelected () {
      return this.selectedLocation &&
        this.selectedLocation.isNotAssignedSelected
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-page {
    &__table-header {
      display: flex;
      justify-content: space-between;
      padding-left: 24px;
      padding-right: 24px;
        span {
            &.text-right {
              white-space: nowrap;
              width: 120px;
            }
          }
    }

    .sidebar-column {
      width: 120px;
      text-align: right;
    }

    .not-assigned {
      display: flex;
    }

    .sidebar-controls {
      width: 130px;
      display: flex;
      justify-content: flex-end;
    }

    .action-buttons--hover {
      width: 72px;
      position: absolute;
      background-color: #e7f7f3;
      top: 0;
      bottom: 0;
      width: auto;
      display: flex;
      align-items: center;
    }

  }

  ::v-deep .sl-vue-tree-selected > .sl-vue-tree-node-item{
        .action-buttons--hover {
        background-color: #d0f0e7;
    }
  }
  ::v-deep .sl-vue-tree-node-item{
    gap: 15px;
    padding-right: 24px;
  }
  ::v-deep .sl-vue-tree-title{
    width: auto;
  }
  ::v-deep .sl-vue-tree-sidebar,
  .header-right--titles {
      width: 370px;
      gap: 15px;
  }
</style>
