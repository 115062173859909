<template lang="pug">
  .intro-page.fill-layout-height
    v-scrollable(:bottom-offset="20")
      .intro-page__content
        img.intro-page__cover.mb-4(src="../../../../assets/img/location-intro.svg")
        h2.title {{ 'pages.administration.create_your_locations' | translate }}
        p.subtitle  {{ 'pages.administration.this_page_helps_you_to_create_locations_and_manage_users' | translate }}
        p.subtitle.mb-4 {{ 'pages.administration.click_the_button_below_to_create_location' | translate }}
        v-btn(@click="onAddLocation") + {{ 'pages.administration.new_location' | translate }}

</template>

<script>
export default {
  name: 'Intro',

  methods: {
    onAddLocation () {
      this.$emit('on-add-location')
    }
  }
}
</script>

<style lang="scss" scoped>
  .intro-page {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        margin-bottom: 1rem !important;
      }
    }

    &__cover {
      width: 45%;
    }

    .subtitle {
      margin: 0;
    }
  }
</style>
